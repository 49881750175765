<template>
  <CModal :show.sync="modalShow" size="lg" centered :close-on-backdrop="false">
    <template #header>
      <slot name="modal-header">
        <h2>
          {{ editMode ? 'Edit' : 'Create' }}
          {{ $tc(`entity.taxEngineConnection`) }}
        </h2>
      </slot>
    </template>
    <CForm
      id="frm-edit-connection"
      :class="{ 'is-loading': isLoading }"
      autocomplete="off"
      @submit.prevent="() => false"
    >
      <input
        id="disable-pwd-mgr-1"
        name="disable-pwd-mgr-1"
        type="password"
        style="display: none"
        value="disable-pwd-mgr-1"
        aria-hidden="true"
      />
      <input
        id="disable-pwd-mgr-2"
        name="disable-pwd-mgr-2"
        type="password"
        style="display: none"
        value="disable-pwd-mgr-2"
        aria-hidden="true"
      />
      <input
        id="disable-pwd-mgr-3"
        name="disable-pwd-mgr-3"
        type="password"
        style="display: none"
        value="disable-pwd-mgr-3"
        aria-hidden="true"
      />
      <CRow v-taxengine>
        <CCol col="6">
          <CSelect
            id="frm-edit-connection_engineType"
            :options="taxEngineOptions"
            :value.sync="engineType"
            placeholder="Tax Engine"
            label="Tax Engine"
            :required="!editMode || !isSet(onlyTaxEngine)"
            :disabled="editMode || isSet(onlyTaxEngine)"
            :read-only="editMode || isSet(onlyTaxEngine)"
            :invalid-feedback="invalidFeedback('engineType')"
            :is-valid="isValid('engineType')"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CInput
            id="frm-edit-connection_name"
            label="Connection Name"
            :value.sync="name"
            required
            :invalid-feedback="invalidFeedback('name')"
            :is-valid="isValid('name')"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol v-show="selectedTaxEngineType !== 'AVA'">
          <CInput
            id="frm-edit-connection_url"
            label="URL"
            placeholder="https://server.domain/path"
            :value.sync="url"
            required
            :invalid-feedback="invalidFeedback('url')"
            :is-valid="isValid('url')"
          />
        </CCol>
        <CCol v-show="selectedTaxEngineType === 'AVA'">
          <MSelect
            id="frm-edit-connection_environment"
            :options="[
              { label: 'Production', value: 'Production' },
              { label: 'Sandbox', value: 'Sandbox' },
            ]"
            :value.sync="environment"
            placeholder="Environment"
            label="Environment"
            :required="selectedTaxEngineType === 'AVA'"
            :disabled="selectedTaxEngineType !== 'AVA'"
            :read-only="selectedTaxEngineType !== 'AVA'"
            :invalid-feedback="invalidFeedback('environment')"
            :is-valid="isValid('environment')"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol :col="selectedTaxEngineType === 'VTX' ? 5 : 12">
          <CInput
            id="frm-edit-connection_username"
            label="Username"
            placeholder="Username"
            :value.sync="username"
            :invalid-feedback="invalidFeedback('username')"
            :is-valid="isValid('username')"
            :disabled="isSet(trustedId)"
            autocomplete="off"
            data-lpignore="true"
            data-1p-ignore="true"
          />
          <CInput
            id="frm-edit-connection_password"
            label="Password"
            type="password"
            placeholder="Password"
            :value.sync="password"
            :invalid-feedback="invalidFeedback('password')"
            :is-valid="isValid('password')"
            :disabled="isSet(trustedId)"
            autocomplete="off"
            data-lpignore="true"
            data-1p-ignore="true"
          />
        </CCol>
        <CCol v-show="selectedTaxEngineType === 'VTX'" col="2">
          <div class="d-flex justify-content-center align-items-center h-100">
            <p class="font-weight-bold">- OR -</p>
          </div>
        </CCol>
        <CCol v-show="selectedTaxEngineType === 'VTX'" col="5">
          <CInput
            id="frm-edit-connection_trustedId"
            label="Trusted ID"
            type="password"
            placeholder="Trusted ID"
            :value.sync="trustedId"
            :invalid-feedback="invalidFeedback('trustedId')"
            :is-valid="isValid('trustedId')"
            :disabled="isSet(password) || isSet(username)"
            autocomplete="off"
            data-lpignore="true"
            data-1p-ignore="true"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CTextarea
            id="frm-edit-connection_description"
            label="Description"
            placeholder="Optional description of the tax engine connection"
            :value.sync="description"
          />
        </CCol>
      </CRow>
      <input type="submit" style="display: none" aria-hidden="true" />
    </CForm>
    <template #footer>
      <CButton
        v-show="formMode === 'CREATE' || !connectionTestSuccess"
        :color="connectionTestSuccess ? 'success' : 'info'"
        :disabled="testConnectionDisabled"
        @click="handleTestConnection"
      >
        Test Connection
      </CButton>
      <CButton
        color="secondary"
        form="frm-edit-connection"
        @click="handleCancel"
        >Cancel</CButton
      >
      <CButton
        color="primary"
        form="frm-edit-connection"
        :disabled="!canSubmit"
        @click="handleSubmit"
        >Save</CButton
      >
    </template>
  </CModal>
</template>
<script>
import api from '@/api';
import JSEncrypt from 'jsencrypt';
const encryptor = new JSEncrypt();
import {
  formsMixin,
  FormMode,
  mapFormProps,
  mapErrorFields,
} from '@/mixins/forms-mixin';
import { mapGetters } from 'vuex';

export default {
  name: 'EditTaxEngineConnectionModal',
  mixins: [formsMixin],
  inheritAttrs: false,
  props: {
    onlyTaxEngine: {
      type: String,
      required: false,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
      required: true,
    },
    value: {
      type: Object,
      required: false,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      isSetup: false,
      requiredFields: ['name', 'engineType'],
      formMode: this.value ? FormMode.EDIT : FormMode.CREATE,
      showState: this.show,
      isLoading: false,
      connectionTestSuccess: false,
      passwordChanged: false,
      trustedIdChanged: false,
      formObject: {
        id: null,
        engineType: this.onlyTaxEngine || null,
        name: null,
        url: null,
        username: null,
        password: null,
        trustedId: null,
        description: null,
        environment: null,
      },
    };
  },
  computed: {
    ...mapFormProps(['name', 'url', 'username', 'description'], {
      rootObjectName: 'formObject',
    }),
    ...mapErrorFields(['engineType', 'password', 'trustedId', 'environment']),
    ...mapGetters('auth', [
      'hasSingleTaxEngine',
      'defaultTaxEngine',
      'taxEngines',
    ]),
    extendedSubmitEvaluation() {
      if (this.engineType === 'AVA') {
        if (!this.username || !this.environment) {
          return false;
        }
        if (!this.editMode && !this.password) {
          return false;
        }
      } else {
        if (!this.url) {
          return false;
        }
      }
      return this.connectionTestSuccess;
    },
    selectedTaxEngineType() {
      if (this.hasSingleTaxEngine) {
        return this.defaultTaxEngine;
      }

      return this.engineType;
    },
    taxEngineOptions() {
      let opts = this.taxEngines || ['SBX', 'VTX', 'AVA'];
      if (this.onlyTaxEngine) {
        opts = [this.onlyTaxEngine.toUpperCase()];
      }

      return opts.map((value) => {
        let label = value;
        switch (value) {
          case 'AVA':
            label = 'Avalara';
            break;
          case 'SBX':
            label = 'ONESOURCE';
            break;
          case 'VTX':
            label = 'Vertex';
            break;
          case 'SOV':
            label = 'Sovos';
            break;
        }
        return {
          value,
          label,
        };
      });
    },
    testConnectionDisabled() {
      if (this.isLoading || !this.engineType || !this.name) {
        return true;
      }

      if (this.engineType === 'AVA') {
        return !this.environment || !this.username || !this.password;
      } else {
        return !this.url;
      }
    },
    submitDisabled() {
      if (this.editMode) {
        if (this.isLoading) {
          return true;
        } else if (this.engineType === 'AVA') {
          return !this.environment || !this.username;
        } else {
          return !this.url;
        }
      }

      // Connection test is required when creating a new connection.
      if (this.isLoading || !this.engineType || !this.connectionTestSuccess) {
        return true;
      }

      if (this.engineType === 'AVA') {
        return !this.environment || !this.username || !this.password;
      } else {
        return !this.url;
      }
    },
    engineType: {
      get() {
        if (!this.editMode && this.hasSingleTaxEngine) {
          return this.defaultTaxEngine;
        }
        return this.getFieldValue('engineType', this.formObject.engineType);
      },
      set(v) {
        if (!this.editMode && this.hasSingleTaxEngine) {
          // Ignore.
        } else {
          this.setPendingChange('engineType', v, this.formObject.engineType);
        }
      },
    },
    environment: {
      get() {
        return this.getFieldValue('environment', this.formObject.environment);
      },
      set(v) {
        if (this.editMode) {
          if (!v?.trim()) {
            this.clearPendingChange('environment');
            this.clearErrors();
            this.connectionTestSuccess = !(
              this.pendingChanges['username'] ||
              this.pendingChanges['password'] ||
              this.pendingChanges['environment']
            );
            this.trustedIdChanged = false;
          } else {
            this.setPendingChange('environment', v);
            this.connectionTestSuccess = false;
            this.trustedIdChanged = true;
          }
        } else {
          this.setPendingChange('environment', v);
          this.connectionTestSuccess = false;
        }
      },
    },
    password: {
      get() {
        return this.getFieldValue('password', this.formObject.password);
      },
      set(v) {
        if (this.editMode) {
          if (!v?.trim()) {
            this.clearPendingChange('password');
            this.clearErrors();
            this.connectionTestSuccess = !(
              this.pendingChanges['url'] ||
              this.pendingChanges['username'] ||
              this.pendingChanges['trustedId']
            );
            this.passwordChanged = false;
          } else {
            this.setPendingChange('password', v);
            this.connectionTestSuccess = false;
            this.passwordChanged = true;
          }
        } else {
          this.setPendingChange('password', v);
          this.connectionTestSuccess = false;
        }
      },
    },
    trustedId: {
      get() {
        return this.getFieldValue('trustedId', this.formObject.trustedId);
      },
      set(v) {
        if (this.editMode) {
          if (!v?.trim()) {
            this.clearPendingChange('trustedId');
            this.clearErrors();
            this.connectionTestSuccess = !(
              this.pendingChanges['url'] ||
              this.pendingChanges['username'] ||
              this.pendingChanges['password']
            );
            this.trustedIdChanged = false;
          } else {
            this.setPendingChange('trustedId', v);
            this.connectionTestSuccess = false;
            this.trustedIdChanged = true;
          }
        } else {
          this.setPendingChange('trustedId', v);
          this.connectionTestSuccess = false;
        }
      },
    },
    modalShow: {
      get() {
        return this.showState;
      },
      set(v) {
        this.showState = v;
        this.$emit('update:show', v === true);
      },
    },
  },
  watch: {
    url() {
      if (this.isSetup) {
        this.connectionTestSuccess = false;
      }
    },
    username() {
      if (this.isSetup) {
        this.connectionTestSuccess = false;
      }
    },
    trustedId() {
      if (this.isSetup) {
        this.connectionTestSuccess = false;
      }
    },
    environment() {
      if (this.isSetup) {
        this.connectionTestSuccess = false;
      }
    },
  },
  mounted() {
    let v = this.value;
    if (!v) {
      this.formMode = FormMode.CREATE;
    } else {
      this.formObject = {
        id: v.id,
        engineType: v.engineType || v.taxEngineType,
        name: v.name,
        url: v.url,
        username: v.username,
        password: '',
        trustedId: '',
        description: v.description,
        environment: v.environment || v.properties?.environment,
      };
      this.formMode = FormMode.EDIT;
      this.connectionTestSuccess = true;
    }

    this.$nextTick(() => {
      this.isSetup = true;
    });

    this.fetchPublicKey();
  },
  methods: {
    handleCancel() {
      this.modalShow = false;
    },
    handleSubmit() {
      const request = {
        engineType: this.engineType,
        name: this.name?.trim(),
        url: this.url?.trim(),
        description: this.description?.trim(),
        username: this.username?.trim(),
        password: this.encryptPassword(this.password),
        environment: this.environment,
        encrypted: true,
      };

      if (this.engineType === 'VTX') {
        request.trustedId = this.encryptPassword(this.trustedId);
      }

      if (this.editMode) {
        request.id = this.value.id;
        request.updatePassword = this.passwordChanged;
        if (!this.passwordChanged) {
          delete request.password;
        }
        if (this.engineType === 'VTX') {
          request.updateTrustedId = this.trustedIdChanged;
          if (!this.trustedIdChanged) {
            delete request.trustedId;
          }
        }
      }

      this.isLoading = true;
      const self = this;
      const submitFunction = () => {
        if (self.editMode) {
          return api.connectivity.save(request);
        } else {
          return api.connectivity.create(request);
        }
      };
      this.submitForm(() => submitFunction())
        .then(({ response, error }) => {
          if (response && !error) {
            self.showSuccess('Connection successfully saved').then(() => {
              self.modalShow = false;
              self.$emit('refresh');
            });
          }
        })
        .finally(() => {
          self.$nextTick(() => {
            self.isLoading = false;
          });
        });
    },
    handleTestConnection() {
      let p = {
        engineType: this.engineType,
        url: this.url,
        username: this.username,
        password: this.encryptPassword(this.password),
        encrypted: true,
        environment: this.environment,
      };

      if (this.selectedTaxEngineType === 'VTX') {
        p.trustedId = this.encryptPassword(this.trustedId);
      }

      this.isLoading = true;
      const self = this;
      this.submitForm(() => {
        return api.connectivity.testConnectivity(
          p.engineType,
          p.url,
          p.username,
          p.password,
          p.trustedId,
          p.environment,
          true,
        );
      })
        .then(({ response, error, skipped }) => {
          if (!skipped && !error && response) {
            self.connectionTestSuccess = response.success === true;
          }
        })
        .finally(() => {
          self.$nextTick(() => {
            self.isLoading = false;
          });
        });
    },
    encryptPassword(password) {
      if (!password || password.length < 1) {
        return null;
      }

      return encryptor.encrypt(password.trim());
    },
    fetchPublicKey() {
      api.connectivity.getPublicKey().then((publicKey) => {
        encryptor.setPublicKey(publicKey);
      });
    },
    isSet(property) {
      return typeof property === 'string' ? property.length > 0 : false;
    },
  },
};
</script>
